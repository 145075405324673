define("elmer/controllers/case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.caseModel = Ember.get(this, 'store').createRecord('case', {});
    },

    actions: {
      rollback(caseChangeset) {
        caseChangeset.rollback();
      },

      submit(caseChangeset) {
        caseChangeset.save().then(() => {
          this.send('refreshModel');
        });
      }

    }
  });

  _exports.default = _default;
});