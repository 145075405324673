define("elmer/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/allergy-list.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/allergy-list.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/case.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/case.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/checkup.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/checkup.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/drug-allergen.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/drug-allergen.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/eye-exam.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/eye-exam.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/food-allergen.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/food-allergen.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/history.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/history.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/management-plan.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/management-plan.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/patient-status.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/patient-status.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/patient.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/patient.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/temp.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/temp.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/allergy-list.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/allergy-list.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/case.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/case.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/checkup.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/checkup.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/drug-allergen.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/drug-allergen.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/eye-exam.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/eye-exam.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/food-allergen.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/food-allergen.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/history.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/history.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/management-plan.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/management-plan.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/patient-status.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/patient-status.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/patient.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/patient.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/temp.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/temp.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});