define("elmer/components/patient/checkups/eye-exam/new", ["exports", "ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      let eyeExamModel = this.get('eyeExam');

      if (eyeExamModel) {
        let changeset = new _emberChangeset.default(eyeExamModel);
        this.set('changesetModel', changeset);
      }
    },

    actions: {
      submit() {
        let changesetModel = this.get('changesetModel');
        return this.get('onSubmit')(changesetModel);
      },

      upload(fileDetails) {
        return this.get('onUpload')(fileDetails);
      }

    }
  });

  _exports.default = _default;
});