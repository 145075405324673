define("elmer/models/history", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    drugAllergens: attr('string'),
    family: attr('string'),
    foodAllergens: attr('string'),
    maintenanceMedications: attr('string'),
    medical: attr('string'),
    otherMeds: attr('string'),
    sexual: attr('string'),
    surgical: attr('string'),
    patient: belongsTo()
  });

  _exports.default = _default;
});