define("elmer/controllers/dashboard/management/food-allergens", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.foodAllergenModel = Ember.get(this, 'store').createRecord('food-allergen', {});
    },

    actions: {
      confirmed() {
        this.send('refreshModel');
      },

      rollback(foodAllergenChangeset) {
        foodAllergenChangeset.rollback();
      },

      async submit(foodAllergenChangeset) {
        try {
          await foodAllergenChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
        }

        this.set('saveSuccess', true);
      }

    }
  });

  _exports.default = _default;
});