define("elmer/routes/dashboard/patients/check-ups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      patient_id
    }) {
      return patient_id;
    },

    setupController(controller, model) {
      const patientRouteModel = this.modelFor('dashboard.patients');
      const patients = patientRouteModel.patients;
      let patient = patients.findBy('id', model);
      controller.set('patient', patient);
    }

  });

  _exports.default = _default;
});