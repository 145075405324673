define("elmer/templates/components/food-allergen/view-food-allergen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5s+aL253",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[5,\"bs-form\",[],[[\"@formLayout\",\"@model\",\"@onSubmit\",\"@disabled\"],[\"vertical\",[23,0,[]],[28,\"action\",[[23,0,[]],\"submit\"],null],true]],{\"statements\":[[0,\"\\n    \\n    \"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@placeholder\",\"@property\"],[\"\",\"\",\"\",\"\"]]],[0,\"\\n    \\n    \"],[5,\"b-s-button\",[],[[\"@defaultText\",\"@type\",\"@buttonType\"],[\"Save\",\"primary\",\"submit\"]]],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/components/food-allergen/view-food-allergen.hbs"
    }
  });

  _exports.default = _default;
});