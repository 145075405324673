define("elmer/helpers/format-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatDate(params, hash) {
    return (0, _moment.default)(hash.date).format(hash.format);
  });

  _exports.default = _default;
});