define("elmer/controllers/dashboard/patients/check-ups/eye-exam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      let eyeExam = this.store.createRecord('eye-exam', {});
      this.set('changesetModel', eyeExam);
    },

    actions: {
      rollback() {
        this.get('changesetModel').delete();
      },

      async submit(eyeExamChangeset) {
        let checkup = this.get('checkup');
        eyeExamChangeset.set('checkup', checkup);
        let savedEyeExam = await eyeExamChangeset.save().catch(() => {//TODO error handling
        });
        checkup.set('eyeExam', savedEyeExam);
        checkup.set('createdAt', new Date(checkup.get('createdAt')));
        await checkup.save().catch(() => {//TODO error handling
        });
      }

    }
  });

  _exports.default = _default;
});