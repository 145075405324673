define("elmer/components/patient/accordion-list", ["exports", "elmer/constants/patient-statuses"], function (_exports, _patientStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    PATIENT_STATUS: _patientStatuses.default
  });

  _exports.default = _default;
});