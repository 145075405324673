define("elmer/templates/components/drug-allergen/create-edit-drug-allergen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PaxxtjMD",
    "block": "{\"symbols\":[\"modal\",\"form\"],\"statements\":[[5,\"bs-modal\",[],[[\"@open\",\"@backdropClose\",\"@size\",\"@onHidden\"],[[22,\"open\"],false,\"lg\",[28,\"queue\",[[28,\"action\",[[23,0,[]],[24,[\"onRollBack\"]],[28,\"changeset\",[[24,[\"changesetModel\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"open\"]]],null],false],null]],null]]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"header\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"drugAllergen\"]]],null,{\"statements\":[[0,\"        Drug allergen\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        New Drug allergen\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n      \"],[5,\"bs-form\",[],[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"vertical\",[28,\"changeset\",[[24,[\"changesetModel\"]]],null],[28,\"action\",[[23,0,[]],\"submit\"],null]]],{\"statements\":[[0,\"\\n        \\n          \\n          \\n          \\n          \\n          \\n          \\n        \"],[6,[23,2,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@placeholder\",\"@property\"],[\"text\",\"Name\",\"Name\",\"name\"]]],[0,\"\\n          \\n        \\n        \"],[5,\"bs-button\",[[12,\"class\",\"light-blue\"]],[[\"@defaultText\",\"@type\",\"@buttonType\"],[\"Save\",\"primary\",\"submit\"]]],[0,\"\\n        \"],[5,\"bs-button\",[[12,\"class\",\"peach\"]],[[\"@defaultText\",\"@type\",\"@onClick\"],[\"Cancel\",\"secondary\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"open\"]]],null],false],null]]]],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/components/drug-allergen/create-edit-drug-allergen.hbs"
    }
  });

  _exports.default = _default;
});