define("elmer/controllers/dashboard/patients/check-ups/case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    eyeExam: Ember.computed('checkup.eyeExam', function () {
      return this.get('checkup.eyeExam');
    }),
    planAndManagement: Ember.computed('checkup.planAndManagement', function () {
      return this.get('checkup.planAndManagement');
    })
  });

  _exports.default = _default;
});