define("elmer/controllers/dashboard/patients/check-ups/eye-exam/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    firebaseApp: Ember.inject.service(),
    session: Ember.inject.service(),

    async init() {
      this._super(...arguments);

      let userUid = this.session.data.authenticated.user.uid;
      let firebaseApp = this.firebaseApp;
      let storage = await firebaseApp.storage();
      let storageRef = storage.ref(userUid);
      this.set('storageRef', storageRef);
    },

    actions: {
      async confirmed() {
        if (this.get('imageSaved')) {
          this.set('imageSaved', false);
          return;
        }

        let savedCheckupId = await this.get('savedCheckupId');
        this.transitionToRoute('dashboard.patients.check-ups.plan-and-management.new', savedCheckupId);
      },

      async submit(eyeExamChangeset) {
        let patients = this.get('patients');
        let patient = patients.findBy('id', this.get('patientId'));
        let checkups = patient.checkups;
        let checkup = checkups.findBy('id', this.get('checkupId'));
        eyeExamChangeset.set('checkup', checkup);

        if (this.get('slitLampODUrl')) {
          eyeExamChangeset.set('slitLampODUrl', this.get('slitLampODUrl'));
        }

        if (this.get('slitLampOSUrl')) {
          eyeExamChangeset.set('slitLampOSUrl', this.get('slitLampOSUrl'));
        }

        if (this.get('fundusODUrl')) {
          eyeExamChangeset.set('fundusODUrl', this.get('fundusODUrl'));
        }

        if (this.get('fundusOSUrl')) {
          eyeExamChangeset.set('fundusOSUrl', this.get('fundusOSUrl'));
        }

        let savedEyeExam;

        try {
          savedEyeExam = await eyeExamChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        checkup.set('eyeExam', savedEyeExam);
        checkup.set('createdAt', new Date(checkup.get('createdAt')));

        try {
          await checkup.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('saveSuccess', true);
        this.set('saveSuccessMessage', 'The patients eye exam details have been saved. You will now be redirected to the plan and management');
        this.set('savedCheckupId', checkup.id);
        this.set('savedEyeExamId', savedEyeExam.id);
      },

      async upload(fileDetails) {
        let checkupId = this.get('checkupId');
        let fileInput = fileDetails.target;
        let file = fileInput.files[0];
        let storageRef = this.get('storageRef');
        let imagesRef = storageRef.child("images/eye-exam/".concat(checkupId, "/").concat(fileInput.id)); //uploading image feedback spinner?

        try {
          await imagesRef.put(file);
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('imageSaved', true);
        this.set('saveSuccess', true);
        this.set('saveSuccessMessage', 'Image saved!');
        let downloadedImage;

        try {
          downloadedImage = await imagesRef.getDownloadURL();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Your image was saved but we had trouble loading the preview. Details: ".concat(error));
          return;
        }

        switch (fileInput.id) {
          case 'slitLampOD':
            this.set('slitLampODUrl', downloadedImage);
            break;

          case 'slitLampOS':
            this.set('slitLampOSUrl', downloadedImage);
            break;

          case 'fundusOD':
            this.set('fundusODUrl', downloadedImage);
            break;

          case 'fundusOS':
            this.set('fundusOSUrl', downloadedImage);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});