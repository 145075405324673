define("elmer/constants/patient-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    CHARITY: 'Charity',
    INFECTIOUS: 'Infectious',
    SENIOR_CITIZEN: 'Senior Citizen',
    UNIVERSAL_PRECAUTION: 'Universal Precaution'
  };
  _exports.default = _default;
});