define("elmer/controllers/dashboard/patients/check-ups/eye-exam/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    firebaseApp: Ember.inject.service(),
    session: Ember.inject.service(),
    editable: false,

    async init() {
      this._super(...arguments);

      let userUid = this.session.data.authenticated.user.uid;
      let firebaseApp = this.firebaseApp;
      let storage = await firebaseApp.storage();
      let storageRef = storage.ref(userUid);
      this.set('storageRef', storageRef);
    },

    actions: {
      async rollback() {
        let eyeExam = await this.get('eyeExam');
        eyeExam.rollbackAttributes();
      },

      async submit() {
        let eyeExam = await this.get('eyeExam');

        if (this.get('slitLampODUrl')) {
          this.set('slitLampODUrl', this.get('slitLampODUrl'));
        }

        if (this.get('slitLampOSUrl')) {
          this.set('slitLampOSUrl', this.get('slitLampOSUrl'));
        }

        if (this.get('fundusODUrl')) {
          this.set('fundusODUrl', this.get('fundusODUrl'));
        }

        if (this.get('fundusOSUrl')) {
          this.set('fundusOSUrl', this.get('fundusOSUrl'));
        }

        try {
          await eyeExam.save();
        } catch (error) {
          this.set('updateErrorMessage', "Details: ".concat(error));
          this.set('updateError', true);
        }

        this.set('editable', false);
        this.set('updateSuccess', true);
      },

      async upload(fileDetails) {
        let checkupId = this.get('checkup').get('id');
        let fileInput = fileDetails.target;
        let file = fileInput.files[0];
        let storageRef = this.get('storageRef');
        let imagesRef = storageRef.child("images/eye-exam/".concat(checkupId, "/").concat(fileInput.id)); //uploading image feedback spinner?

        try {
          await imagesRef.put(file);
        } catch (error) {
          this.set('updateError', true);
          this.set('updateErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('updateSuccess', true);
        this.set('updateSuccessMessage', 'Image saved!');
        let downloadedImage;

        try {
          downloadedImage = await imagesRef.getDownloadURL();
        } catch (error) {
          this.set('updateError', true);
          this.set('updateErrorMessage', "Your image was saved but we had trouble loading the preview. Details: ".concat(error));
          return;
        }

        switch (fileInput.id) {
          case 'slitLampOD':
            this.set('eyeExam.slitLampODUrl', downloadedImage);
            break;

          case 'slitLampOS':
            this.set('eyeExam.slitLampOSUrl', downloadedImage);
            break;

          case 'fundusOD':
            this.set('eyeExam.fundusODUrl', downloadedImage);
            break;

          case 'fundusOS':
            this.set('eyeExam.fundusOSUrl', downloadedImage);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});