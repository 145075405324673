define("elmer/controllers/dashboard/patients/check-ups/plan-and-management/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    spectacleOptions: Ember.A([{
      title: 'Yes',
      value: true
    }, {
      title: 'No',
      value: false
    }]),
    actions: {
      async confirmed() {
        this.transitionToRoute('dashboard.patients.full', this.get('patientId'));
      },

      async submit(planAndMgmtChangeset) {
        if (!this.get('selectedRadio')) {
          this.set('selectedRadio', false);
        }

        let patients = this.get('patients');
        let patient = patients.findBy('id', this.get('patientId'));
        let checkups = patient.checkups;
        let checkup = checkups.findBy('id', this.get('checkupId'));
        planAndMgmtChangeset.set('checkup', checkup);
        planAndMgmtChangeset.set('spectacles', this.get('selectedRadio'));
        let savedPlanAndMgmt;

        try {
          savedPlanAndMgmt = await planAndMgmtChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        checkup.set('planAndManagement', savedPlanAndMgmt);
        checkup.set('createdAt', new Date(checkup.get('createdAt')));

        try {
          await checkup.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('saveSuccess', true);
      }

    }
  });

  _exports.default = _default;
});