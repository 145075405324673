define("elmer/models/case", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    demographics: belongsTo('demographics'),
    chiefComplaint: attr('string'),
    histories: hasMany('history'),
    allergyList: belongsTo('allergy-list'),
    medications: hasMany('medication'),
    reviewOfSystems: attr('string'),
    eyeExam: belongsTo('eye-exam'),
    diagnosis: attr('string'),
    managementPlan: belongsTo('management-plan')
  });

  _exports.default = _default;
});