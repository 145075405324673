define("elmer/controllers/dashboard/patients/list", ["exports", "elmer/constants/patient-statuses"], function (_exports, _patientStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    PATIENT_STATUS: _patientStatuses.default,
    actions: {
      confirmed() {
        if (this.get('transitionAfter')) {
          let transitionDetails = this.get('transitionDetails');

          if (transitionDetails.route === 'dashboard.patients.check-ups.eye-exam.new') {
            this.transitionToRoute(transitionDetails.route, transitionDetails.patientId, transitionDetails.checkupId);
          }

          if (transitionDetails.route === 'dashboard.patients.full') {
            this.transitionToRoute(transitionDetails.route, transitionDetails.patientId);
          }
        } else {
          this.send('refreshModel');
        }
      },

      destroyNewCheckup() {
        Ember.get(this, 'checkupModel').deleteRecord();
      },

      async destroyNewHistory() {
        let history = await this.get('currentPatient').get('history');

        if (!history) {
          Ember.get(this, 'historyModel').deleteRecord();
        }
      },

      newCheckupRecord() {
        Ember.set(this, 'checkupModel', Ember.get(this, 'store').createRecord('checkup', {
          createdAt: new Date(),
          patient: Ember.get(this, 'currentPatient')
        }));
      },

      async newHistoryRecord() {
        let history = await this.get('currentPatient').get('history');

        if (!history) {
          history = Ember.get(this, 'store').createRecord('history', {
            patient: Ember.get(this, 'currentPatient')
          });
        }

        await this.set('historyModel', history);
      },

      rollbackPatient(patientChangeset) {
        return patientChangeset.rollback();
      },

      async searchByName() {
        Ember.set(this, 'statusSearch', '');
        let firstName = this.get('firstNameSearch');
        let surname = this.get('surnameSearch');
        let charity = this.get('charitySearch');
        let infectious = this.get('infectiousSearch');
        let senior = this.get('seniorSearch');
        let universal = this.get('universalSearch');

        if (firstName) {
          let matchingPatients = await Ember.get(this, 'store').query('patient', {
            where: ['firstName', '==', firstName]
          });
          this.set('model', matchingPatients);
        } else if (surname) {
          let matchingPatients = await Ember.get(this, 'store').query('patient', {
            where: ['surname', '==', surname]
          });
          this.set('model', matchingPatients);
        } else if (charity) {//TODO
        } else if (infectious) {//TODO
        } else if (senior) {//TODO
        } else if (universal) {//TODO
        } else {
          let matchingPatients = await this.store.findAll('patient');
          this.set('model', matchingPatients);
        }
      },

      setCurrentPatient(patient) {
        Ember.set(this, 'currentPatient', patient);
      },

      async statusSearch() {
        Ember.set(this, 'nameSearch', '');
        let statusArr = Ember.get(this, 'statusSearch');
        let matchingPatients = await Ember.get(this, 'store').query('patient', {
          status: statusArr
        });
        Ember.set(this, 'model', matchingPatients);
      },

      async submitCheckup(checkupChangeset) {
        let patient = Ember.get(this, 'currentPatient');
        let savedCheckup;

        try {
          savedCheckup = await checkupChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('saveSuccess', true);
        this.set('saveSuccessTitle', 'Checkup saved!');
        this.set('saveSuccessMessage', 'You will be redirected to the patients eye exam after clicking ok');
        this.set('transitionAfter', true);
        this.set('transitionDetails', {
          route: 'dashboard.patients.check-ups.eye-exam.new',
          patientId: patient.id,
          checkupId: savedCheckup.id
        });
      },

      async submitHistory(historyChangeset) {
        let patient = Ember.get(this, 'currentPatient');
        let savedHistory;

        try {
          savedHistory = await historyChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        patient.set('history', savedHistory);

        try {
          await patient.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('saveSuccess', true);
        this.set('saveSuccessTitle', 'History saved!');
        this.set('saveSuccessMessage', 'You will be redirected after clicking ok');
        this.set('transitionAfter', true);
        this.set('transitionDetails', {
          route: 'dashboard.patients.full',
          patientId: patient.id
        });
      },

      async updatePatient(patientChangeset) {
        let patientStatus = await this.get('currentPatient').get('patientStatus');
        patientStatus.set('charity', patientChangeset.get('charity'));
        patientStatus.set('infectious', patientChangeset.get('infectious'));
        patientStatus.set('seniorCitizen', patientChangeset.get('seniorCitizen'));
        patientStatus.set('universalPrecaution', patientChangeset.get('universalPrecaution'));

        try {
          await patientStatus.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        try {
          await patientChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('saveSuccess', true);
        this.set('saveSuccessTitle', 'Patient updated!');
        this.set('saveSuccessMessage', '');
      }

    }
  });

  _exports.default = _default;
});