define("elmer/router", ["exports", "elmer/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('case');
    this.route('management-plan');
    this.route('login', {
      path: '/'
    });
    this.route('dashboard', function () {
      this.route('patients', function () {
        this.route('list', {
          path: '/'
        });
        this.route('full', {
          path: ':patient_id'
        });
        this.route('check-ups', {
          path: '/:patient_id/checkups'
        }, function () {
          this.route('eye-exam', {
            path: '/:checkup_id/eye-exam'
          }, function () {
            this.route('new');
            this.route('view', {
              path: '/:eye_exam_id'
            });
          });
          this.route('plan-and-management', {
            path: '/:checkup_id/plan-and-management'
          }, function () {
            this.route('new');
            this.route('view', {
              path: '/:plan_and_management_id'
            });
          });
          this.route('list', {
            path: '/'
          });
          this.route('view', {
            path: '/:checkup_id'
          });
          this.route('case', {
            path: '/:checkup_id/case'
          });
        });
      });
      this.route('management', {
        path: '/'
      }, function () {
        this.route('drug-allergens', {
          path: '/drug-allergens'
        });
        this.route('food-allergens', {
          path: '/food-allergens'
        });
      });
    });
    this.route('logout');
    this.route('sign-up');
  });
  var _default = Router;
  _exports.default = _default;
});