define("elmer/controllers/dashboard/patients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.patientModel = '';
    },

    actions: {
      confirmed() {
        this.send('refreshRoute');
        this.transitionToRoute('dashboard.patients.full', this.get('savedPatientId'));
      },

      destroyNewPatient() {
        Ember.get(this, 'patientModel').deleteRecord();
      },

      newPatient() {
        Ember.set(this, 'patientModel', Ember.get(this, 'store').createRecord('patient', {}));
        let sessionData = this.get('session.data');
        let email = sessionData.authenticated.user.email;
        let newPatient = this.get('patientModel');
        newPatient.set('user', email);
      },

      rollback(patientChangeset) {
        patientChangeset.rollback();
      },

      async submit(patientChangeset) {
        let patientStatus = await this.store.createRecord('patient-status', {
          charity: patientChangeset.get('charity'),
          infectious: patientChangeset.get('infectious'),
          seniorCitizen: patientChangeset.get('seniorCitizen'),
          universalPrecaution: patientChangeset.get('universalPrecaution')
        });
        let savedPatientStatus;

        try {
          savedPatientStatus = await patientStatus.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        patientChangeset.set('patientStatus', savedPatientStatus);
        let savedPatient;

        try {
          savedPatient = await patientChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        savedPatientStatus.set('patient', savedPatient);

        try {
          await savedPatientStatus.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('saveSuccess', true);
        this.set('saveSuccessMessage', 'You will be redirected to the full patient page after clicking ok.');
        this.set('savedPatientId', savedPatient.id);
      }

    }
  });

  _exports.default = _default;
});