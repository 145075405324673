define("elmer/models/patient", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    address1: attr('string'),
    address2: attr('string'),
    contactNumber: attr('string'),
    dob: attr('date'),
    emailAddress: attr('string'),
    firstName: attr('string'),
    gender: attr('string'),
    middleName: attr('string'),
    occupation: attr('string'),
    surname: attr('string'),
    user: attr('string'),
    checkups: hasMany(),
    history: belongsTo(),
    patientStatus: belongsTo()
  });

  _exports.default = _default;
});