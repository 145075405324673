define("elmer/controllers/dashboard/patients/full", ["exports", "elmer/constants/patient-statuses"], function (_exports, _patientStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    PATIENT_STATUS: _patientStatuses.default,
    drugAllergensList: Ember.computed('drugAllergens', async function () {
      let drugAllergens = await this.get('drugAllergens');

      if (drugAllergens) {
        let list = [];
        drugAllergens.forEach(function (drug) {
          if (drug.name) {
            list.push(drug.name);
          }
        });
        return list;
      }
    }),
    foodAllergensList: Ember.computed('foodAllergens', async function () {
      let foodAllergens = await this.get('foodAllergens');

      if (foodAllergens) {
        let list = [];
        foodAllergens.forEach(function (food) {
          if (food.name) {
            list.push(food.name);
          }
        });
        return list;
      }
    }),
    actions: {
      confirmed() {
        if (this.get('transitionAfter')) {
          let patient = this.get('patient');
          let checkupId = this.get('savedCheckupId');
          this.transitionToRoute('dashboard.patients.check-ups.eye-exam.new', patient.id, checkupId);
        } else {
          this.send('refreshModel');
        }
      },

      destroyNewCheckup() {
        Ember.get(this, 'checkupModel').deleteRecord();
      },

      rollbackPatient(patientChangeset) {
        return patientChangeset.rollback();
      },

      async rollbackHistory() {
        let history = await Ember.get(this, 'patient').history;

        if (history.isNew) {
          await Ember.get(this, 'historyModel').deleteRecord();
        }
      },

      newCheckupRecord() {
        Ember.set(this, 'checkupModel', Ember.get(this, 'store').createRecord('checkup', {
          createdAt: new Date(),
          patient: Ember.get(this, 'patient')
        }));
      },

      async newOrGetHistoryRecord() {
        let history = await Ember.get(this, 'patient').history;

        if (!history) {
          history = await Ember.get(this, 'store').createRecord('history', {
            patient: Ember.get(this, 'patient')
          });
        }

        await Ember.set(this, 'historyModel', history);
      },

      async submit(patientChangeset) {
        let patientStatus = await this.get('patient').get('patientStatus');
        patientStatus.set('charity', patientChangeset.get('charity'));
        patientStatus.set('infectious', patientChangeset.get('infectious'));
        patientStatus.set('seniorCitizen', patientChangeset.get('seniorCitizen'));
        patientStatus.set('universalPrecaution', patientChangeset.get('universalPrecaution'));

        try {
          await patientStatus.save();
        } catch (error) {
          this.set('updateError', true);
          this.set('updateErrorMessage', "Details: ".concat(error));
        }

        try {
          await patientChangeset.save();
        } catch (error) {
          this.set('updateError', true);
          this.set('updateErrorMessage', "Details: ".concat(error));
        }

        this.set('updateSuccess', true);
        this.set('updateSuccessTitle', 'Patient demographic updated!');
        this.set('updateSuccessMessage', '');
      },

      async submitCheckup(checkupChangeset) {
        let savedCheckup;

        try {
          savedCheckup = await checkupChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
          return;
        }

        this.set('updateSuccess', true);
        this.set('updateSuccessTitle', 'Checkup saved');
        this.set('updateSuccessMessage', 'You will be redirected to the patients eye exam after clicking ok');
        this.set('transitionAfter', true);
        this.set('savedCheckupId', savedCheckup.id);
      },

      async submitHistory(historyChangeset) {
        let savedHistory;

        try {
          savedHistory = await historyChangeset.save();
        } catch (error) {
          this.set('updateError', true);
          this.set('updateErrorMessage', "Details: ".concat(error));
        }

        let patient = Ember.get(this, 'patient');
        patient.set('history', savedHistory);
        patient.set('dob', new Date(patient.get('dob')));

        try {
          await patient.save();
        } catch (error) {
          this.set('updateError', true);
          this.set('updateErrorMessage', "Details: ".concat(error));
        }

        this.set('updateSuccess', true);
        this.set('updateSuccessTitle', 'History Updated!');
        this.set('updateSuccessMessage', '');
      }

    }
  });

  _exports.default = _default;
});