define("elmer/templates/components/food-allergen/food-allergen-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c9wMd3xT",
    "block": "{\"symbols\":[\"foodAllergen\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"table table-striped table-sm table-borderless\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[10,\"scope\",\"col\"],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"foodAllergens\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"id\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/components/food-allergen/food-allergen-table.hbs"
    }
  });

  _exports.default = _default;
});