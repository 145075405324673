define("elmer/models/plan-and-management", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    diagnoses: attr('string'),
    //diagnosesTags: attr(),
    followUp: attr('string'),
    labsAndProcedures: attr('string'),
    medications: attr('string'),
    surgeries: attr('string'),
    spectacles: attr('boolean', {
      defaultValue: false
    }),
    checkup: belongsTo() //medications: hasMany('medication'), //since emberfire one to many doesnt work the way laravel one to many works out of the box, ill just use an array of strings for now

  });

  _exports.default = _default;
});