define("elmer/routes/dashboard/patients/full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model({
      patient_id
    }) {
      let sessionData = this.get('session.data');
      let email = sessionData.authenticated.user.email;
      return Ember.RSVP.hash({
        patientId: patient_id,
        patients: this.store.query('patient', {
          where: ['user', '==', email]
        })
      });
    },

    async setupController(controller, model) {
      const patientRouteModel = await this.modelFor('dashboard.patients');
      const patients = await model.patients;
      let patient = await patients.findBy('id', model.patientId);
      controller.set('drugAllergens', patientRouteModel.drugAllergens);
      controller.set('foodAllergens', patientRouteModel.foodAllergens);
      controller.set('patient', patient);
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});