define("elmer/mirage/factories/checkup", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    createdDate() {
      return (0, _moment.default)();
    }

  });

  _exports.default = _default;
});