define("elmer/templates/sign-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lGzJFphQ",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"sign-up-form-container\"],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"sign-up-form\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"mx-auto\"],[8],[0,\"Sign Up\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[8],[1,[22,\"errorMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[5,\"bs-form\",[[12,\"class\",\"mb-2\"]],[[\"@formLayout\",\"@model\",\"@onSubmit\",\"@submitOnEnter\"],[\"vertical\",[23,0,[]],[28,\"action\",[[23,0,[]],\"signUp\"],null],true]],{\"statements\":[[0,\"\\n      \"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@placeholder\",\"@property\",\"@required\"],[\"email\",\"Email\",\"Email\",\"email\",true]]],[0,\"\\n      \"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@placeholder\",\"@property\",\"@required\"],[\"password\",\"Password\",\"Password\",\"password\",true]]],[0,\"\\n      \"],[6,[23,1,[\"element\"]],[],[[\"@controlType\",\"@label\",\"@placeholder\",\"@property\",\"@required\"],[\"password\",\"Password\",\"Confirm Password\",\"confirmPassword\",true]]],[0,\"\\n      \"],[5,\"bs-button\",[[12,\"class\",\"btn-block\"]],[[\"@defaultText\",\"@type\",\"@buttonType\"],[\"Sign Up\",\"primary\",\"submit\"]]],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"login\"]],{\"statements\":[[0,\"Already a user? Log in here\"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/sign-up.hbs"
    }
  });

  _exports.default = _default;
});