define("elmer/templates/dashboard/patients/check-ups/plan-and-management/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0+XWxJXC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hr\",true],[8],[9],[0,\"\\n\"],[5,\"patient/checkups/plan-and-management/new\",[],[[\"@checkupId\",\"@patientId\",\"@planAndManagement\",\"@onSubmit\"],[[22,\"checkupId\"],[22,\"patientId\"],[22,\"planAndManagement\"],[28,\"action\",[[23,0,[]],\"submit\"],null]]]],[0,\"\\n\\n\"],[1,[28,\"sweet-alert\",null,[[\"show\",\"title\",\"text\",\"type\",\"onConfirm\"],[[24,[\"saveSuccess\"]],\"Plan and Management Saved!\",\"You will be redirected to the patients full chart after clicking ok.\",\"success\",[28,\"action\",[[23,0,[]],\"confirmed\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"sweet-alert\",null,[[\"show\",\"title\",\"text\",\"type\"],[[24,[\"saveError\"]],\"Oh no! Something went wrong.\",[24,[\"saveErrorMessage\"]],\"error\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/dashboard/patients/check-ups/plan-and-management/new.hbs"
    }
  });

  _exports.default = _default;
});