define("elmer/components/patient/checkups/plan-and-management/diagnoses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    sortedCheckupsAsc: Ember.computed.sort('checkups.@each.createdAt', function (checkup1, checkup2) {
      let date1 = new Date(checkup1.createdAt);
      let date2 = new Date(checkup2.createdAt);
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    }),
    firstCheckup: Ember.computed('sortedCheckupsAsc.@each.planAndManagement', function () {
      let checkups = this.get('sortedCheckupsAsc');
      if (checkups.length > 0) return checkups.firstObject;
    }),
    firstPlanAndManagement: Ember.computed('firstCheckup', function () {
      let checkup = this.get('firstCheckup');
      if (checkup) return checkup.planAndManagement;
    }),
    firstDiagnoses: Ember.computed('firstPlanAndManagement.diagnoses', function () {
      let planAndManagement = this.get('firstPlanAndManagement');

      if (planAndManagement) {
        return planAndManagement.get('diagnoses');
      }
    }),
    lastCheckup: Ember.computed('sortedCheckupsAsc.@each.planAndManagement', function () {
      let checkups = this.get('sortedCheckupsAsc');

      if (checkups.length > 0) {
        return checkups.lastObject;
      }
    }),
    lastPlanAndManagement: Ember.computed('lastCheckup', function () {
      let checkup = this.get('lastCheckup');

      if (checkup) {
        return checkup.planAndManagement;
      }
    }),
    lastDiagnoses: Ember.computed('lastPlanAndManagement.diagnoses', function () {
      let planAndManagement = this.get('lastPlanAndManagement');

      if (planAndManagement) {
        return planAndManagement.get('diagnoses');
      }
    })
  });

  _exports.default = _default;
});