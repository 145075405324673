define("elmer/templates/dashboard/patients/check-ups/eye-exam/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QNXioP/Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hr\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[10,\"class\",\"col-lg-2 col-sm-2\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"patient\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"patient-fullname\",[[24,[\"patient\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"patient/checkups/eye-exam/new\",[],[[\"@checkupId\",\"@eyeExam\",\"@patientId\",\"@onSubmit\",\"@onUpload\"],[[22,\"checkupId\"],[22,\"eyeExam\"],[22,\"patientId\"],[28,\"action\",[[23,0,[]],\"submit\"],null],[28,\"action\",[[23,0,[]],\"upload\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"sweet-alert\",null,[[\"show\",\"title\",\"text\",\"type\",\"onConfirm\"],[[24,[\"saveSuccess\"]],\"Saved!\",[24,[\"saveSuccessMessage\"]],\"success\",[28,\"action\",[[23,0,[]],\"confirmed\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"sweet-alert\",null,[[\"show\",\"title\",\"text\",\"type\"],[[24,[\"saveError\"]],\"Oh no! Something went wrong.\",[24,[\"saveErrorMessage\"]],\"error\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/dashboard/patients/check-ups/eye-exam/new.hbs"
    }
  });

  _exports.default = _default;
});