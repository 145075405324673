define("elmer/routes/dashboard/patients/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller) {
      const patientModel = this.modelFor('dashboard.patients');
      controller.set('drugAllergens', patientModel.drugAllergens);
      controller.set('foodAllergens', patientModel.foodAllergens);
      controller.set('model', patientModel.patients);
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});