define("elmer/controllers/dashboard/drug-allergen/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      submit(drugAllergen) {
        drugAllergen.save().then(() => {
          this.transitionToRoute('dashboard');
        }).catch(errors => {
          this.set('errors', errors);
        });
      }

    }
  });

  _exports.default = _default;
});