define("elmer/models/patient-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    charity: attr('boolean', {
      defaultValue: false
    }),
    infectious: attr('boolean', {
      defaultValue: false
    }),
    seniorCitizen: attr('boolean', {
      defaultValue: false
    }),
    universalPrecaution: attr('boolean', {
      defaultValue: false
    }),
    patient: belongsTo('patient')
  });

  _exports.default = _default;
});