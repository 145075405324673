define("elmer/helpers/patient-has-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*deprecated*/
  var _default = Ember.Helper.helper(function patientHasStatus(params) {
    const [patient, status] = params;
    let statuses = patient.patientStatuses.map(function (status) {
      return status.type;
    });
    return statuses.includes(status);
  });

  _exports.default = _default;
});