define("elmer/controllers/dashboard/management/drug-allergens", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.drugAllergenModel = Ember.get(this, 'store').createRecord('drug-allergen', {});
    },

    actions: {
      confirmed() {
        this.send('refreshModel');
      },

      rollback(drugAllergenChangeset) {
        drugAllergenChangeset.rollback();
      },

      async submit(drugAllergenChangeset) {
        try {
          await drugAllergenChangeset.save();
        } catch (error) {
          this.set('saveError', true);
          this.set('saveErrorMessage', "Details: ".concat(error));
        }

        this.set('saveSuccess', true);
      }

    }
  });

  _exports.default = _default;
});