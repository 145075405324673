define("elmer/controllers/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    actions: {
      logOut() {
        return this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});