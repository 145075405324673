define("elmer/mirage/config", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '/api';    // make this `/api`, for example, if your API is namespaced
    this.timing = 400; // delay for each request, automatically set to 0 during testing

    /*CASES*/

    this.get('/cases', function (schema
    /*, request*/
    ) {
      return schema.patients.all();
    });
    this.post('/cases', function (schema) {
      let attrs = this.normalizedRequestAttrs();
      return schema.patients.create(attrs);
    });
    /*CHECKUPS*/

    this.get('/checkups', function (schema
    /*, request*/
    ) {
      return schema.checkups.all();
    });
    this.post('/checkups', function (schema) {
      let attrs = this.normalizedRequestAttrs();
      attrs.createdAt = (0, _moment.default)();
      return schema.checkups.create(attrs);
    });
    /*DRUG ALLERGENS*/

    this.get('/drug-allergens', function (schema
    /*, request*/
    ) {
      return schema.drugAllergens.all();
    });
    this.post('/drug-allergens', function (schema) {
      let attrs = this.normalizedRequestAttrs();
      return schema.drugAllergens.create(attrs);
    });
    /*FOOD ALLERGENS*/

    this.get('/food-allergens', function (schema
    /*, request*/
    ) {
      return schema.foodAllergens.all();
    });
    this.post('/food-allergens', function (schema) {
      let attrs = this.normalizedRequestAttrs();
      return schema.foodAllergens.create(attrs);
    });
    /*HISTORIES*/

    this.get('/histories', function (schema) {
      return schema.histories.all();
    });
    this.post('/histories', function (schema) {
      let attrs = this.normalizedRequestAttrs();
      return schema.histories.create(attrs);
    });
    /*PATIENTS*/

    this.get('/patients', function (schema, request) {
      if (request.queryParams.name) {
        return schema.patients.where(patient => {
          return patient.firstName.toLowerCase() === request.queryParams.name.toLowerCase() || patient.surname.toLowerCase() === request.queryParams.name.toLowerCase();
        });
      }

      if (request.queryParams.status) {
        return schema.patients.where(patient => {
          if (patient.patientStatuses) {
            let statuses = patient.patientStatuses.map(function (status) {
              return status.type;
            });
            return statuses.some(request.queryParams.status);
          }
        });
      }

      return schema.patients.all();
    });
    this.get('/patients/:id', function (schema, request) {
      let id = request.params.id;
      return schema.patients.find(id);
    });
    this.post('/patients', function (schema) {
      let attrs = this.normalizedRequestAttrs();
      return schema.patients.create(attrs);
    });
    this.patch('/patients/:id', function (schema, request) {
      let id = request.params.id;
      let patient = schema.patients.find(id);
      let attrs = this.normalizedRequestAttrs();
      return patient.update(attrs);
    });
    /*PATIENT STATUSES*/

    this.get('/patient-statuses', function (schema
    /*, request*/
    ) {
      return schema.patientStatuses.all();
    });
    this.get('/patient-statuses/:id', function (schema, request) {
      let id = request.params.id;
      return schema.patientStatuses.find(id);
    });
  }
});