define("elmer/models/eye-exam", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    uncorrectedVAOD: attr('string'),
    uncorrectedVAOS: attr('string'),
    correctedVAOD: attr('string'),
    correctedVAOS: attr('string'),
    intraocularPressureOD: attr('string'),
    intraocularPressureOS: attr('string'),
    slitLampODUrl: attr('string'),
    slitLampOSUrl: attr('string'),
    slitLampODNotes: attr('string'),
    slitLampOSNotes: attr('string'),
    fundusODUrl: attr('string'),
    fundusOSUrl: attr('string'),
    fundusODNotes: attr('string'),
    fundusOSNotes: attr('string'),
    checkup: belongsTo()
  });

  _exports.default = _default;
});