define("elmer/mirage/factories/temp", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    age() {
      return 19;
    },

    name() {
      return "Reggie";
    },

    notes() {
      return "Some kind of notes.";
    }

  });

  _exports.default = _default;
});