define("elmer/mirage/factories/food-allergen", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return _faker.default.lorem.word();
    }

  });

  _exports.default = _default;
});