define("elmer/controllers/dashboard/patients/check-ups/plan-and-management/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editable: false,
    spectacleOptions: Ember.A([{
      title: 'Yes',
      value: true
    }, {
      title: 'No',
      value: false
    }]),
    actions: {
      async rollback() {
        let planAndMgmt = await this.get('planAndMgmt');
        planAndMgmt.rollbackAttributes();
      },

      async submit() {
        let planAndMgmt = await this.get('planAndMgmt');

        try {
          await planAndMgmt.save();
        } catch (error) {
          this.set('updateErrorMessage', "Details: ".concat(error));
          this.set('updateError', true);
        }

        this.set('editable', false);
        this.set('updateSuccess', true);
      }

    }
  });

  _exports.default = _default;
});