define("elmer/components/patient/checkups/plan-and-management/new", ["exports", "ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      let planAndManagementModel = this.get('planAndManagement');

      if (planAndManagementModel) {
        let changeset = new _emberChangeset.default(planAndManagementModel);
        this.set('changesetModel', changeset);
      }
    },

    spectacleOptions: Ember.A([{
      title: 'Yes',
      value: true
    }, {
      title: 'No',
      value: false
    }]),
    actions: {
      submit() {
        let changesetModel = this.get('changesetModel');
        return this.get('onSubmit')(changesetModel);
      }

    }
  });

  _exports.default = _default;
});