define("elmer/helpers/patient-fullname", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function patientFullname(params) {
    let [patient] = params;
    return "".concat(patient.firstName, " ").concat(patient.surname);
  });

  _exports.default = _default;
});