define("elmer/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */
    //server.createList('temp', 10);

    /*server.create('patient', {
      checkups: [
        server.create('checkup', {
          caseHistory: 'Itchy eyes',
          chiefComplaint: 'Eyes have been itchy for the past 4 days.'
        })
      ]
    });*/
    server.create('drug-allergen', {
      name: 'Amoxicillin'
    });
    server.create('drug-allergen', {
      name: 'Ibuprofen'
    });
    server.create('drug-allergen', {
      name: 'Atracurium'
    });
    server.create('food-allergen', {
      name: 'Shellfish'
    });
    server.create('food-allergen', {
      name: 'Peanuts'
    });
    server.create('food-allergen', {
      name: 'Pinapple'
    });
    server.create('food-allergen', {
      name: 'Dairy'
    });
    server.create('patient-status', {
      type: 'Charity'
    });
    server.create('patient-status', {
      type: 'Infectious'
    });
    server.create('patient-status', {
      type: 'Senior Citizen'
    });
    server.create('patient-status', {
      type: 'Universal Precaution'
    });
    server.createList('patient', 5);
    /*let patient1 = server.create('patient', {});
    let patient2 = server.create('patient', {});
    let patient3 = server.create('patient', {});
    let patient4 = server.create('patient', {});
    let patient5 = server.create('patient', {});*/
  }
});