define("elmer/routes/dashboard/patients/check-ups/eye-exam/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async setupController(controller) {
      const patientRouteModel = this.modelFor('dashboard.patients');
      const patientId = this.modelFor('dashboard.patients.check-ups');
      const checkupId = this.modelFor('dashboard.patients.check-ups.eye-exam');
      const patients = patientRouteModel.patients;
      let patient = await patients.findBy('id', patientId);
      let checkups = await patient.checkups;
      let checkup = await checkups.findBy('id', checkupId);
      let eyeExam = checkup.eyeExam;
      controller.set('checkup', checkup);
      controller.set('patient', patient);
      controller.set('eyeExam', eyeExam);
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});