define("elmer/controllers/dashboard/patients/check-ups/plan-and-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      let planAndManagement = this.store.createRecord('plan-and-management', {});
      this.set('changesetModel', planAndManagement);
    },

    spectacleOptions: Ember.A(['Yes', 'No']),
    actions: {
      rollback() {
        this.get('changesetModel').delete();
      },

      submit(planAndManagementChangeset) {
        planAndManagementChangeset.save();
      }

    }
  });

  _exports.default = _default;
});