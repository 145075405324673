define("elmer/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    actions: {
      async login(model) {
        const auth = await this.get('firebaseApp').auth();
        return await auth.signInWithEmailAndPassword(model.email, model.password).then(() => {
          this.transitionToRoute('dashboard.patients.list');
        }).catch(error => {
          this.set('errorMessage', error.message);
        });
      }

    }
  });

  _exports.default = _default;
});