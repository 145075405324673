define("elmer/templates/components/patient/checkups/plan-and-management/diagnoses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R+ZtVumF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"sortBy\"]],\"asc\"],null]],null,{\"statements\":[[0,\"  \"],[1,[22,\"firstDiagnoses\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"sortBy\"]],\"desc\"],null]],null,{\"statements\":[[0,\"  \"],[1,[22,\"lastDiagnoses\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/components/patient/checkups/plan-and-management/diagnoses.hbs"
    }
  });

  _exports.default = _default;
});