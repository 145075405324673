define("elmer/routes/dashboard/patients/check-ups/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const patientId = this.modelFor('dashboard.patients.check-ups');
      return this.store.query('checkup', {
        where: ['patient', '==', patientId],
        orderBy: {
          createdAt: 'desc'
        }
      });
    },

    setupController(controller, model) {
      const patientRouteModel = this.modelFor('dashboard.patients');
      const patientId = this.modelFor('dashboard.patients.check-ups');
      const patients = patientRouteModel.patients;
      let patient = patients.findBy('id', patientId);
      controller.set('patient', patient);
      controller.set('checkups', model);
    }

  });

  _exports.default = _default;
});