define("elmer/routes/dashboard/patients/check-ups/eye-exam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      checkup_id
    }) {
      return checkup_id;
    },

    async setupController(controller, model) {
      const patientRouteModel = this.modelFor('dashboard.patients');
      const patientId = this.modelFor('dashboard.patients.check-ups');
      const patients = patientRouteModel.patients;
      let patient = await patients.findBy('id', patientId);
      let checkups = await patient.checkups;
      let checkup = checkups.findBy('id', model);
      controller.set('checkup', checkup);
      controller.set('patient', patient);
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});