define("elmer/mirage/factories/patient", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    address1() {
      return _faker.default.address.streetAddress();
    },

    address2() {
      return _faker.default.address.city();
    },

    contactNumber() {
      return _faker.default.phone.phoneNumberFormat(0);
    },

    dob() {
      return _faker.default.date.past();
    },

    firstName() {
      return _faker.default.name.firstName();
    },

    gender() {
      return getRandomGender();
    },

    occupation() {
      return _faker.default.name.jobType();
    },

    surname() {
      return _faker.default.name.lastName();
    }

  });

  _exports.default = _default;

  let getRandomGender = function () {
    return Math.floor(Math.random() * 10) % 2 ? 'Female' : 'Male';
  };
});