define("elmer/mirage/models/history", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    drugAllergen: (0, _emberCliMirage.hasMany)(),
    foodAllergen: (0, _emberCliMirage.hasMany)(),
    patient: (0, _emberCliMirage.belongsTo)()
  });

  _exports.default = _default;
});