define("elmer/routes/dashboard/patients/check-ups/plan-and-management/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.createRecord('plan-and-management', {});
    },

    setupController(controller, model) {
      const patientRouteModel = this.modelFor('dashboard.patients');
      const patientId = this.modelFor('dashboard.patients.check-ups');
      const checkupId = this.modelFor('dashboard.patients.check-ups.plan-and-management');
      const patients = patientRouteModel.patients;
      controller.set('checkupId', checkupId);
      controller.set('patientId', patientId);
      controller.set('patients', patients);
      controller.set('planAndManagement', model);
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});