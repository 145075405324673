define("elmer/components/patient/create-edit-patient", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    async init() {
      this._super(...arguments);

      let patient = await this.get('patient');
      let patientStatus;
      let changesetModel = await this.get('changesetModel');

      if (patient) {
        patientStatus = await patient.get('patientStatus');
        changesetModel.set('charity', patientStatus.get('charity'));
        changesetModel.set('infectious', patientStatus.get('infectious'));
        changesetModel.set('seniorCitizen', patientStatus.get('seniorCitizen'));
        changesetModel.set('universalPrecaution', patientStatus.get('universalPrecaution'));
      }
    },

    async didReceiveAttrs() {
      this._super(...arguments);

      let patient = await this.get('patient');
      let patientStatus;
      let changesetModel = await this.get('changesetModel');

      if (patient) {
        patientStatus = await patient.get('patientStatus');
        changesetModel.set('charity', patientStatus.get('charity'));
        changesetModel.set('infectious', patientStatus.get('infectious'));
        changesetModel.set('seniorCitizen', patientStatus.get('seniorCitizen'));
        changesetModel.set('universalPrecaution', patientStatus.get('universalPrecaution'));
      }
    },

    genderOptions: Ember.A(['Female', 'Male']),
    actions: {
      submit(form) {
        form.set('dob', (0, _moment.default)(form.get('dob'), 'YYYY-MM-DD').toDate());
        this.get('onSubmit')(form);
      }

    }
  });

  _exports.default = _default;
});