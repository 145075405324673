define("elmer/templates/dashboard/patients/check-ups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EvkKR2Gq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"d-block mb-3 other-patient-actions\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"dashboard.patients.full\",[24,[\"patient\",\"id\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"d-inline-block\"],[8],[1,[28,\"patient-fullname\",[[24,[\"patient\"]]],null],false],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/dashboard/patients/check-ups.hbs"
    }
  });

  _exports.default = _default;
});