define("elmer/controllers/dashboard/patients/check-ups/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    visitTypes: Ember.A(['New', 'Follow-up', 'Post-op', 'Procedure', 'ROP', 'ROP follow-up', 'Prescription only', 'Spectacles only']),
    actions: {
      async rollback() {
        let checkup = await this.get('checkup');
        checkup.rollbackAttributes();
      },

      async submit() {
        let checkup = await this.get('checkup');

        try {
          await checkup.save();
        } catch (error) {
          this.set('updateErrorMessage', "Details: ".concat(error));
          this.set('updateError', true);
        }

        this.set('editable', false);
        this.set('updateSuccess', true);
      }

    }
  });

  _exports.default = _default;
});