define("elmer/controllers/sign-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    firebaseApp: Ember.inject.service(),
    actions: {
      async signUp(model) {
        const auth = await this.get('firebaseApp').auth();

        if (this._matchPasswords(model.password, model.confirmPassword)) {
          await auth.createUserWithEmailAndPassword(model.email, model.password).then(() => {
            this.transitionToRoute('dashboard.patients.list');
          }).catch(error => {
            this.set('errorMessage', error.message);
          });
        } else {
          this.set('errorMessage', 'Passwords do not match');
        }
      }

    },

    _matchPasswords(password, confirm) {
      return password === confirm;
    }

  });

  _exports.default = _default;
});