define("elmer/routes/dashboard/patients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model() {
      let sessionData = this.get('session.data');
      let email = sessionData.authenticated.user.email;
      return Ember.RSVP.hash({
        drugAllergens: this.store.findAll('drug-allergen'),
        foodAllergens: this.store.findAll('food-allergen'),
        patients: this.store.query('patient', {
          where: ['user', '==', email]
        })
      });
    },

    setupController(controller, model) {
      controller.set('drugAllergens', model.drugAllergens);
      controller.set('foodAllergens', model.foodAllergens);
      controller.set('model', model.patients);
    },

    actions: {
      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});