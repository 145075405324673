define("elmer/templates/dashboard/patients/check-ups/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2oPwAHDG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"patient\",\"checkups\"]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"dashboard.patients.check-ups.view\",[24,[\"checkups\",\"firstObject\",\"id\"]]]],{\"statements\":[[0,\"Go to latest check up\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"font-weight-bold\"],[8],[0,\"Check Ups\"],[9],[0,\"\\n  \"],[5,\"patient/past-checkups/accordion-list\",[],[[\"@checkups\",\"@patient\",\"@setCurrentCheckup\"],[[22,\"checkups\"],[22,\"patient\"],[28,\"action\",[[23,0,[]],\"setCurrentCheckup\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"strong\"],[8],[0,\"Patient has not had any check ups.\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/dashboard/patients/check-ups/list.hbs"
    }
  });

  _exports.default = _default;
});