define("elmer/components/patient/checkups/entry-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    visitTypes: Ember.A(['New', 'Follow-up', 'Post-op', 'Procedure', 'ROP', 'ROP follow-up', 'Prescription only', 'Spectacles only']),
    actions: {
      submit(form) {
        this.get('onSubmit')(form);
      }

    }
  });

  _exports.default = _default;
});