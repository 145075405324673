define("elmer/templates/dashboard/patients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Qh6EPzu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex justify-content-between flex-wrap flex-md-nowrap algin-items-center pt-3 pb-2 mb-3 border-bottom\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"dashboard.patients.list\"]],{\"statements\":[[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"h2\"],[8],[0,\"Patients\"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"btn-toolbar mb-2 mb-md-0\"],[8],[0,\"\\n    \"],[5,\"bs-button\",[[12,\"class\",\"btn btn-sm btn-outline-secondary\"]],[[\"@type\",\"@onClick\"],[\"button\",[28,\"pipe\",[[28,\"action\",[[23,0,[]],\"newPatient\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showCreateModal\"]]],null],true],null]],null]]],{\"statements\":[[0,\"\\n      New Patient\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"patient/create-edit-patient\",[],[[\"@patient\",\"@changesetModel\",\"@open\",\"@onClose\",\"@onRollback\",\"@onCancel\",\"@onSubmit\"],[[22,\"selectedPatient\"],[22,\"patientModel\"],[22,\"showCreateModal\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedpatient\"]]],null],null],null],[28,\"action\",[[23,0,[]],\"rollback\"],null],[28,\"action\",[[23,0,[]],\"destroyNewPatient\"],null],[28,\"queue\",[[28,\"action\",[[23,0,[]],\"submit\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showCreateModal\"]]],null],false],null]],null]]]],[0,\"\\n\\n\"],[1,[28,\"sweet-alert\",null,[[\"show\",\"title\",\"text\",\"type\",\"onConfirm\"],[[24,[\"saveSuccess\"]],\"Patient saved!\",[24,[\"saveSuccessMessage\"]],\"success\",[28,\"action\",[[23,0,[]],\"confirmed\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"sweet-alert\",null,[[\"show\",\"title\",\"text\",\"type\"],[[24,[\"saveError\"]],\"Oh no! Something went wrong.\",[24,[\"saveErrorMessage\"]],\"error\"]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "elmer/templates/dashboard/patients.hbs"
    }
  });

  _exports.default = _default;
});